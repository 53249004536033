import { ReactElement } from 'react';

import Image from 'components/UI/Image';
import Link from '../UI/Link';
import { IComponentImageWithTitleFields } from 'types/contentful';
import { MAX_SCREEN_WIDTH } from 'lib/consts';

export default function ImageWithTitle({
  heading,
  image,
  imageLink,
  openInNewTab,
  linkLabel,
}: IComponentImageWithTitleFields): ReactElement {
  const isExternal = imageLink?.startsWith('http');
  const target = openInNewTab !== undefined && openInNewTab === true ? '_blank' : '';

  const NoLink = (): ReactElement => (
    <Image image={image} query={`w=${MAX_SCREEN_WIDTH}`} classNames="w-full h-full object-cover" />
  );

  const WithLink = (): ReactElement => {
    const external = (
      <a href={imageLink} target={target} rel="noreferrer" aria-label={linkLabel}>
        <Image
          image={image}
          query={`w=${MAX_SCREEN_WIDTH}`}
          classNames="w-full h-full object-cover"
        />
      </a>
    );

    const internal = (
      <Link slug={imageLink as string}>
        <a target={target} aria-label={linkLabel}>
          <Image
            image={image}
            query={`w=${MAX_SCREEN_WIDTH}`}
            classNames="w-full h-full object-cover"
          />
        </a>
      </Link>
    );

    return isExternal ? external : internal;
  };

  return (
    <div>
      <h2 className="font-heading  text-2xl lg:text-4xl ml-4 lg:ml-24 my-6">{heading}</h2>
      <div>{imageLink ? <WithLink /> : <NoLink />}</div>
    </div>
  );
}
